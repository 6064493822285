import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import {thanksHolder } from './Sections.module.scss'
import { Fade } from 'react-awesome-reveal'

const Thank = ({image, href, title, children}) => <li>
    <a href={href} title={title} target="_blank" rel="noreferrer">
        {children}
      </a> 
  </li>

export default function Thanks() {
  return (
    <div className={thanksHolder}>
      <h2>Made possible thanks to:</h2>
      <Fade damping={0.1} direction="up" triggerOnce={true} cascade>

      <ul>
        <Thank image="arts-council" title="British Arts Council" href="https://www.artscouncil.org.uk/">
          <StaticImage
              src={`../../images/thanks/arts-council.png`}
              alt="British Arts Council"
              quality={80}
              height={50}
            />
        </Thank>
        <Thank title=" Jerwood Arts - Live Work Fund" href="https://jerwoodarts.org/opportunities/live-work-fund/">
          <StaticImage
              src={`../../images/thanks/live-work-fund.png`}
              alt="Jerwood Arts - Live Work Fund"
              quality={80}
              height={50}
            />
        </Thank>
        <Thank title="Sennheiser" href="https://en-uk.sennheiser.com/">
          <StaticImage
              src={`../../images/thanks/sennheiser.png`}
              alt="Sennheisser"
              quality={80}
              height={40}
            />
        </Thank>
        <Thank title="Dice.fm" href="https://dice.fm/">
          <StaticImage
              src={`../../images/thanks/dice.png`}
              alt="Dice.fm"
              quality={80}
              height={60}
            />
        </Thank>
        <Thank title="Finisterre" href="https://finisterre.com/">
          <StaticImage
              src={`../../images/thanks/finisterre.png`}
              alt="Finisterre"
              quality={80}
              height={50}
            />
        </Thank>
        <Thank title="Mad Cow Productions" href="https://www.madcowfilms.co.uk/">
          <StaticImage
              src={`../../images/thanks/madcow.png`}
              alt="Mad Cow Productions"
              quality={80}
              height={50}
            />
        </Thank>
      </ul>
      </Fade>
    </div>
  )
}
