import React, {useRef,useState} from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {AnimatePresence, motion} from 'framer-motion'
import {headphoneFormHolder} from './HeadphoneForm.module.scss'

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup
    .string('Enter your name')
    .required('Your name is required'),
});

const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    htmlFontSize: 10,
  },
  palette: {
    type: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#fff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  }
});

const variants = {
  pre:{
    opacity:0,
    y:'-2rem'
  },
  visible: {
    opacity:1,
    y:0
  },
  post: {
    opacity:0,
    y:'2rem'
  }
}

const Contact = ({ setLoading, setResult}) => {


  const formRef = useRef()

  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }


  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true)

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "ContactForm",
          "name": values.name,
          "email": values.email
        })
      }).then(() => {
        setLoading(false)
        setResult('success')
      })
      .catch(error => {
        setResult('error')
        setLoading(false)
        alert(error)
      })
    },
  });



  return (
    <motion.div key="contactForm" variants={variants} initial="pre" animate="visible" exit="post" >
    <form      
      onSubmit={formik.handleSubmit}
      name="ContactForm"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      id="mailing-list-form"
      ref={formRef}>
        <input type="hidden" name="form-name" value="ContactForm" />
        <TextField
          id="name"
          name="name"
          label="Your Name"
          type="name"
          fullWidth
          variant="filled"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          fullWidth
          variant="filled"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Button color="primary" variant="contained"  type="submit" size="large">
          Submit
        </Button>
    </form>
    </motion.div>
  );
};

export default function ContactForm({query}) {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)

  const resetForm = () => {
    setLoading(false)
    setResult(null)
  }

  return (
    <div className={headphoneFormHolder} id="contact-form">

      <ThemeProvider theme={theme}>
      <AnimatePresence exitBeforeEnter>
      { (result===null) ? 
        <Contact query={query} setResult={setResult} setLoading={setLoading} />
        : 
        <motion.div key="results" className="resultsHolder" variants={variants} initial="pre" animate="visible" exit="post">
          {
            (result==='success') ?
            <div className="results">
              <p>Thank you for your entry!</p>
            </div>
            :
            <div className="results">
              <p>Sorry, something went wrong:</p>
              <p><button disabled={loading} onClick={()=>{ resetForm() }}>Try again</button></p>
            </div>
          }
        </motion.div>
      }
      </AnimatePresence>
      </ThemeProvider>
    </div>
  )
}

