import React from 'react'
import { livestreamPremiereHolder } from '../styles/pages.module.scss'
import Seo from '../components/utilities/Seo'
import TWYWLogo from '../images/logo/TheWomanYouWant.svg'
import LiveChat from '../components/components/LiveChat'
import MerchStore from '../components/components/MerchStore'
import Headphones from '../components/components/Headphones'
import Thanks from '../components/components/Thanks'


export default function LivestreamPage() {
  const youtubeID = process.env.GATSBY_YOUTUBE_ID



  return (
    <article className={livestreamPremiereHolder}>
      <Seo 
        title="The Woman You Want Livestream Event"
        description="On Wednesday 11th August Eliza Shaddad is coming to your living rooms live from Cornwall to premiere a series of videos that were recorded at Battersea Arts Centre with a 10 piece event. The event will also feature a number of live solo performances, behind the scenes mini-documentary, headphone giveaway and a live Q&A."
      />
      <h1>The Woman You Want</h1>
      <div className="titleHolder">
        <TWYWLogo />
      </div>
      <h2 className="topH2">Global Live Premiere</h2>
      <div className="videoHolder" >
        <iframe
          src={`https://www.youtube.com/embed/${youtubeID}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </div>


    <div className="sections">
    
      <section className="merchStoreSection">
        <MerchStore />
      </section>

      <section className="thanksSection">
        <Thanks />
      </section>

    </div>

    </article>
  )
}
