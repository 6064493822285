import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { merchStoreHolder } from './Sections.module.scss'
import { Fade } from "react-awesome-reveal";

const Merch = ({image, href, title, children, price}) => <li>
    <a href={href} title={title} target="_blank" rel="noreferrer">
        {children}
        <div className="info">
          <h4>{title}</h4>
          <p>{price}</p>
        </div>
      </a> 
  </li>

export default function MerchStore() {
  return (
    <div className={merchStoreHolder}>
      <h2>Official Merch</h2>
      <Fade damping={0.1} direction="up" triggerOnce={true} cascade>
        <ul>
          <Merch title="The Woman You Want Tee" href="https://townsendmusic.store/product/82848" price="£20">
            <StaticImage
                src={`../../images/merch/tee.jpg`}
                alt="The Woman You Want Tee"
                quality={80}
                height={244}
              />
          </Merch>
          <Merch title="Long Sleeve Tee" href="https://townsendmusic.store/product/82852" price="£25">
            <StaticImage
                src={`../../images/merch/longsleeve.jpg`}
                alt="The Woman You Want Longsleeve Tee"
                quality={80}
                height={244}
              />
          </Merch>
          <Merch title="Limited Edition Vinyl" href="https://townsendmusic.store/product/82846" price="£20">
            <StaticImage
                src={`../../images/merch/vinyl.jpg`}
                alt="The Woman You Want Tee"
                quality={80}
                height={244}
              />
          </Merch>
          <Merch title="The Woman You Want CD" href="https://townsendmusic.store/product/82847" price="£10">
            <StaticImage
                src={`../../images/merch/cd.jpg`}
                alt="The Woman You Want CD"
                quality={80}
                height={244}
              />
          </Merch>
        </ul> 
      </Fade>
      <div className="links">
      <a href="https://townsendmusic.store/products/artist/Eliza+Shaddad" title="Eliza Shaddad Merch" className="button" target="_blank" rel="noreferrer">More "The Woman You Want" merch</a>
      <div>or visit Eliza's <a href="https://elizashaddad.bandcamp.com/merch" className="bandcampLink" target="_blank" rel="noreferrer">Bandcamp store</a> for previous merch</div>
      </div>
    </div>
  )
}
